<template>
  <el-form
    v-if="formModel"
    :rules="formRules"
    :model="formModel"
    ref="formModel"
    label-width="140px"
    label-suffix="："
    @submit.native.stop.prevent="handleFormSubmit('formModel')"
  >
    <el-form-item
      label="等级名称"
      prop="name"
    >
    <el-col :md="9">
      <el-input
        v-model="formModel.name"
        placeholder=""
      ></el-input>
      </el-col>
    </el-form-item>

    <el-form-item
      label="等级权重"
      prop="level"
    ><el-col :md="9">
      <el-input
        type="number"
        v-model="formModel.level"
        placeholder=""
        :min="1"
      >
      </el-input>
      </el-col>
    </el-form-item>

    <el-form-item
      type="number"
      label="所需积分"
      prop="integral"
    >
    <el-col :md="9">
      <el-input
        v-model="formModel.integral"
        placeholder=""
      ></el-input>
    </el-col>
    </el-form-item>

    <el-form-item
      label="等级折扣"
      prop="discount"
    >
    <el-col :md="9">
      <el-input
        type="number"
        step="0.01"
         :min="0.01"
        v-model="formModel.discount"
        placeholder=""
      ></el-input>
    </el-col>
    </el-form-item>

    <el-form-item
      label="功能图"
    >
      <uploader
        :avatar="formModel.cover_function"
        @on-upload-success="handleUploadSuccess"
      ></uploader>
    </el-form-item>

    <!-- 内容 -->
     <el-form-item label='内容数据'>
      <div class="b-card">

        <!-- 新增 -->
        <div
          style="margin-bottom:20px;"
          v-for="(item, index) in formModel.member_body"
          :label="'域名' + index"
          :key="item.key"
        >
          <div
            class="b-card"
            style="width:80%;"
          >
            <el-input
              style="margin-bottom:20px;"
              v-model="item.title"
              placeholder='请输入标题'
            ></el-input>
            <el-input
              style="margin-bottom:20px;"
              type="textarea"
              v-model="item.body"
              placeholder='请输入内容...'
            ></el-input>
            <el-button @click.prevent="removeBody(item)">删除</el-button>
          </div>
        </div>

        <!-- 按钮 -->

        <el-button @click="addBody">添加</el-button>
      </div>
    </el-form-item>

    <el-form-item>
      <el-button
        type="primary"
        native-type="submit"
        :loading="submitLoading"
      >{{ submitText }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import Uploader from '@admin/components/uploaders/Uploader'

export default {
  components: { Uploader },
  name: 'LevelForm',
  props: {
    submitText: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object
    }
  },
  data () {
    return {
      submitLoading: false,
      formRules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        level: [
          { required: true, message: '请输入权重', trigger: 'blur' }
        ],

        integral: [
          { required: true, message: '请输入积分', trigger: 'blur' }
        ],
        discount: [
          { required: true, message: '请输入折扣率', trigger: 'blur' }
        ]
      },
      formModel: null
    }
  },

  mounted () {
    this.formModel = Object.assign(this.model)
  },
  methods: {
    handleUploadSuccess (url) {
      this.formModel.cover_function = url
    },
    handleFormSubmit (formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) {
          return false
        }

        this.submitLoading = true

        this.$emit(
          'on-submit',
          this.formModel,
          () => {
            this.$refs[formName].clearValidate()
          },
          () => {
            this.submitLoading = false
          }
        )
      })
    },
    // 内容
    removeBody (item) {
      var index = this.formModel.member_body.indexOf(item)
      if (index !== -1) {
        this.formModel.member_body.splice(index, 1)
      }
    },
    addBody () {
      this.formModel.member_body.push({
        title: '',
        body: ''
      })
    }
  }
}
</script>
<style lang='' scoped>
</style>
